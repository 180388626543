import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { createBrowserHistory } from "history";

import { Image } from 'office-ui-fabric-react/lib/Image';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { getDocumentoData, getDocumentoContentData } from '../../api/sei-documento-responses-api';

import {
    Flex,
    Button,
    Loader, ContactGroupCallIcon
} from "@fluentui/react-northstar";

import './wopi-document-actions.scss';

import WopiDocumentSamples from './wopi-document-sample';

interface IWopiDocumentActionsPageState {
    theme: string,
    actionUrl: string,
    loader: boolean,

    userObjectId: string,
    tenantId: string,
    userPrincipalName: string,

    // SEI:
    documentoId: string,
    documentoData: any,
    unidadeId: string,
    processoId: string,
    
    // Wopi:
    fileId: string,
    wopiAction: string,
    fileName: string,
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}

const browserHistory = createBrowserHistory({ basename: "" });

export class WopiDocumentActionsPage extends React.Component<{}, IWopiDocumentActionsPageState> {
    private telemetry?: any = null;
    private theme?: string | null;
    private locale?: string | null;
    private userObjectId?: string = "";
    private userPrincipalName?: string = "";
    private tenantId?: string = "";
    
    private appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };

    constructor(props: any) {
        super(props);

        console.log("Wopi control loaded");

        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.theme = params.get("theme");
        this.telemetry = this.appSettings.telemetry = params.get("telemetry");
        this.locale = params.get("locale");

        this.state = {
            loader: true,
            theme: this.theme ? this.theme : "default",

            tenantId: "",
            userObjectId: "",
            userPrincipalName: "",

            // Request url parameters:
            actionUrl: params.get("wau") ?? "",
            documentoId: params.get("did") ?? "",
            
            unidadeId: params.get("uid") ?? "",
            processoId: params.get("pid") ?? "",

            wopiAction: params.get("wa") ?? "",
            
            fileId: "",
            fileName: "",

            // Comes from SEI:
            documentoData: null,
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }

    /**
    * Used to initialize Microsoft Teams sdk
    */
    public async componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.userObjectId = context.userObjectId;
            this.tenantId = context.tid;
            this.userPrincipalName = context.userPrincipalName;

            this.setState({
                userObjectId: context.userObjectId ?? "",
                userPrincipalName: context.userPrincipalName ?? "",
                tenantId: context.tid ?? "",
            });

            this.getDocumentResponse();
        });
    }
    
    /**
    * Get Document Response from API
    */
    private async getDocumentResponse() {
        this.setState({ loader: true });

        let response = await getDocumentoData(this.state.documentoId, this.state.unidadeId);

        if (response.status === 200 && response.data) {
            this.setState({
                documentoData: response.data.data,
            });

            this.handleFormSubmit();
        }

        this.setState({ loader: false });
    }

    private handleFormSubmit = () => {

        // @ts-ignore
        var frameholder = document.getElementById('documentFrameholder');

        var office_frame = document.createElement('iframe');

        office_frame.name = 'document_frame';
        office_frame.id = 'document_frame';
        // The title should be set for accessibility
        office_frame.title = 'Document Frame';
        // This attribute allows true fullscreen mode in slideshow view
        // when using PowerPoint Online's 'view' action.
        office_frame.setAttribute('allowfullscreen', 'true');
        // @ts-ignore
        frameholder.appendChild(office_frame);
        // @ts-ignore
        document.getElementById('document_form').submit();
    }

    private renderForm(): JSX.Element {

        var fileId = `${this.state.userObjectId}-${this.state?.unidadeId}-${this.state?.processoId}-${this.state?.documentoId}`

        return (
            <div className="wopi-document-container">
                <div style={{ display: this.state.loader ? 'none' : 'block' }}>
                    <form id="document_form" name="document_form" target="document_frame" action={this.state.actionUrl} method="post" >
                        <input name="documentHtml" value={this.state.documentoData?.visualizar?.data} type="hidden" />
                        <input name="fileId" value={fileId} type="hidden" />
                        <input name="fileName" value={this.state.documentoData?.nomeDocumento} type="hidden" />
                        <input name="action" value={this.state.wopiAction} type="hidden" />
                        <input name="tenantId" value={this.state.tenantId} type="hidden" />
                        <input name="userId" value={this.state.userObjectId} type="hidden" />
                        <input name="userUpn" value={this.state.userPrincipalName} type="hidden" />
                    </form>
                    <span id="documentFrameholder"></span>
                </div>
                <div style={{ display: this.state.loader ? 'block' : 'none' }}>
                    <Flex gap="gap.small" hAlign="center" vAlign="center" styles={{ margin: "20% 0" }}>
                        <Flex.Item align="end">
                            <Loader label="Carregando..." />
                        </Flex.Item>
                    </Flex>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <>{this.renderForm()}</>
        );
    }
}