import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Input, Button, Flex, Layout, Image, Dropdown, Alert, Dialog, TextArea } from "@fluentui/react-northstar";
import { ArrowLeftIcon } from '@fluentui/react-icons-northstar';
import * as microsoftTeams from "@microsoft/teams-js";
import { setHelpDetails } from '../../api/sei-help-api';
import { createBrowserHistory } from "history";

import './sei-help.scss'

interface IAuthProps {
    handler: any;
}

interface IState {
    userId: string,
    assunto: any,
    assuntoSelected: number,
    assuntoId: string,
    titulo: string,
    descricao: string,
    loading: boolean,
    errorMessage: string,
    showDialog: boolean,
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}

const browserHistory = createBrowserHistory({ basename: "" });

export default class SeiHelp extends React.Component<IAuthProps, IState>  {

    telemetry: string = "";
    userObjectId: string = "";
    tenantId: string = "";

    appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };

    constructor(props: IAuthProps) {
        super(props);

        this.telemetry = this.appSettings.telemetry = this.getQueryVariable("telemetry") ?? "";

        this.state = {
            userId: this.getQueryVariable('user') ?? "",
            assunto: [
                'Dúvida',
                'Sugestão',
                'Reclamação',
                'Problema',
            ],
            assuntoSelected: 0,
            assuntoId: "",
            titulo: "",
            descricao: "",
            loading: false,
            errorMessage: "",
            showDialog: false,
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }

    private getQueryVariable(variable: any) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    /**
    * Used to initialize Microsoft Teams sdk
    */
    public componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.userObjectId = context.userObjectId ?? "";
            this.tenantId = context.tid ?? "";
        });
    }

    private async onSave() {
        this.setState({ loading: true });

        if (this.state.assuntoId == "") {
            this.setState({ loading: false });
            this.setState({
                errorMessage: "O campo Assunto é obrigatório!",
            });
            return;
        }

        if (this.state.titulo == "") {
            this.setState({ loading: false });
            this.setState({
                errorMessage: "O campo Título é obrigatório!",
            });
            return;
        }

        if (this.state.descricao == "") {
            this.setState({ loading: false });
            this.setState({
                errorMessage: "O campo Descrição é obrigatório!",
            });
            return;
        }

        const helpResponse = await setHelpDetails(
            {
                partitionKey: this.tenantId,
                userId: this.userObjectId,
                assunto: this.state.assuntoId,
                titulo: this.state.titulo,
                descricao: this.state.descricao,
            }
        );

        if (helpResponse) {
            if (helpResponse.status === 200) {
                this.setState({ loading: false, showDialog: true });
            }
        }
    }

    public render_errorMesage(): JSX.Element {
        if (this.state.errorMessage != "") {
            return (<Alert danger content={this.state.errorMessage} />);
        }
        else {
            return (<div />);
        }
    }

    private onTituloChanged(event: any) {
        this.setState({ titulo: event.target.value });
    }

    private onDescricaoChanged(event: any) {
        this.setState({ descricao: event.target.value });
    }

    public render(): JSX.Element {
        return (
            <div className="content-form-help">
                <Image
                    src="/images/tour-askaquestion.png"
                />
                <h3>Ajuda</h3>
                <h4>Se você tem alguma dúvida ou gostaria de reportar algum problema que esta acontecendo no SEI 365,
                    preencha o formulário abaixo:
                </h4>

                <div className="formContainer">

                    {this.state.showDialog &&
                        <Dialog
                            confirmButton="Ok"
                            onConfirm={() => window.close()}
                            header=""
                            open={true}
                            content="Seu pedido de ajuda foi enviado com sucesso, Obrigado! Em breve entraremos em contato com você!"
                        />
                    }

                    <div className="form-group-dd">
                        <label>Assunto:</label>
                        <Dropdown
                            fluid
                            defaultActiveSelectedIndex={this.state.assuntoSelected}
                            items={this.state.assunto}
                            getA11ySelectionMessage={{
                                onAdd: (item: any) => {
                                    this.setState({
                                        assuntoId: item,
                                    });
                                    return item.id;
                                }
                            }}
                            placeholder="Assunto"
                        />
                    </div>
                    <div className="form-group">
                        <label>Título</label>
                        <Input placeholder="Título" onChange={this.onTituloChanged.bind(this)} value={this.state.titulo} />
                    </div>
                    <div className="form-group">
                        <label>Descrição</label>
                        <TextArea placeholder="Descrição" onChange={this.onDescricaoChanged.bind(this)} value={this.state.descricao} />
                    </div>
                    <div className="form-group buttons">
                        <Flex gap="gap.smaller">
                            <Button content="Voltar" icon={<ArrowLeftIcon />} primary onClick={() => this.props.handler(false)} />
                            <Button content="Enviar" loading={this.state.loading} disabled={this.state.loading} primary onClick={this.onSave.bind(this)} />
                        </Flex>
                    </div>
                    <div>
                        {this.render_errorMesage()}
                    </div>
                </div>
            </div>
        );
    }
};
