import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { createBrowserHistory } from "history";
import {
    Grid,
    Segment,
    Label,
    Menu,
    Checkbox,
    Box,
    Text,
    Flex,
    Button,
    Header,
    Loader,
    Portal,
    Input,
    Dropdown,
    Divider,
    RetryIcon
} from "@fluentui/react-northstar";
import { getDocumentoData, getAssinantes, getOrgaos, signDocument, getDocumentoContentData } from '../../api/sei-documento-responses-api';
import "./sei-document-view.scss";

interface IState {
    theme: string,
    documentoId: string,
    loader: boolean,
    documentoData: any,
    unidadeId: string,
    openSignPortal: boolean,
    assinantes: any,
    orgaos: any,
    orgaoSelected: string,
    assinanteSelected: string,
    userPwd: string,
    message: string,
    signing: boolean,
    errorMessage: string | null,
    loadingDocument: boolean,
    loadingAssinantes: boolean,
    loadingOrgaos: boolean,
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}


//const browserHistory = createBrowserHistory({ basename: "" });

export class DocumentViewPage extends React.Component<{}, IState> {

    token?: string | null = null;
    telemetry?: any = null;
    theme?: string | null;
    locale?: string | null;
    userObjectId?: string = "";
    tenantId?: string = "";
    documentoId?: string = "";
    orgaoSelected?: string = "";
    assinanteSelected?: string = "";
    canSign: boolean = false;

    appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };
    
    constructor(props: any) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.theme = params.get("theme");
        this.canSign = params.get("canSign") == "True";
        this.telemetry = this.appSettings.telemetry = params.get("telemetry");
        this.locale = params.get("locale");

        this.documentoId = params.get("documentoId") ?? "";

        this.state = {
            loader: true,
            theme: this.theme ? this.theme : "default",
            documentoId: params.get("documentoId") ?? "",
            documentoData: null,
            unidadeId: params.get("unidadeId") ?? "",
            openSignPortal: false,
            assinantes: [],
            orgaos: [],
            orgaoSelected: "",
            assinanteSelected: "",
            userPwd: "",
            message: "",
            signing: false,
            errorMessage: null,
            loadingDocument: true,
            loadingAssinantes: true,
            loadingOrgaos: true,
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }

    /**
    * Used to initialize Microsoft Teams sdk
    */
    public async componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.userObjectId = context.userObjectId;
            this.tenantId = context.tid;

            this.init();
        });
    }

    /**
     *  Initialize API calls
    */
    private async init() {
        this.getDocumentResponse();
        this.getAssinantesResponse();
        this.getOrgaosResponse();
    }

    /**
    *Get Document Response from API
    */
    private async getDocumentResponse() {
        this.setState({ loadingDocument: true });

        let response = await getDocumentoData(this.documentoId, this.state.unidadeId);

        // let documentContentResponse = await getDocumentoContentData(this.documentoId, this.state.unidadeId);
        
        if (response.status === 200 && response.data) {
            this.setState({
                documentoData: response.data.data,
            })
        } else {
            this.setState({
                errorMessage: "Estamos com instabilidade para accesar o SEI, tente novamente em alguns minutos.",
            })
        }

        this.setState({ loadingDocument: false });
    }

    /**
    *Get Assinantes Response from API
    */
    private async getAssinantesResponse() {
        this.setState({ loadingAssinantes: true });

        let response = await getAssinantes();
        if (response.status === 200 && response.data) {
            var assinantes = response.data.data?.map((item : any) => {
                return {
                    id: item.id,
                    nome: item.nome,
                    content: item.nome
                };
            });

            assinantes = assinantes ?? [];

            this.setState({
                assinantes: assinantes,
            })
        }

        this.setState({ loadingAssinantes: false });
    }

    /**
    *Get Orgaos Response from API
    */
    private async getOrgaosResponse() {
        this.setState({ loadingOrgaos: true });

        let response = await getOrgaos();
        if (response.status === 200 && response.data) {
            var orgaos = response.data.data.map((item: any) => {
                return {
                    id: item.id,
                    sigla: item.sigla,
                    content: item.sigla
                };
            });

            this.setState({
                orgaos: orgaos,
            })
        }
        
        this.setState({ loadingOrgaos: false });
    }

    private openSignPortal = () => {
        this.setState({
            openSignPortal: true,
        })
    }

    private closeSignPortal = () => {
        this.setState({
            openSignPortal: false,
        })
    }

    private changeOrgao = {
        onAdd: (item: any) => {
            this.setState({
                orgaoSelected: item.id,
            });
            console.log("Orgao selecionado: " + item.id);
            return item.id;
        }
    }

    private changeAssinatura = {
        onAdd: (item: any) => {
            this.setState({
                assinanteSelected: item.nome,
            });
            console.log("Assinatura selecionada: " + item.nome);
            return item.id;
        }
    }

    public render_errorMesage(): JSX.Element {
        if (this.state.message != "") {
            return (<div> { this.state.message } </div>);
        }
        else {
            return (<div />);
        }
    }

    private onPwdChanged(event: any) {
        var userPwd = event.target.value;
        this.setState({
            userPwd: userPwd
        });
    }

    private async signDocument() {

        this.setState({
            message: "",
            signing: true,
        });

        if (this.state.orgaoSelected == "") {
            this.setState({
                message: "Favor preencher o campo Orgão.",
                signing: false,
            });
            return;
        }

        if (this.state.assinanteSelected == "") {
            this.setState({
                message: "Favor preencher o campo Cargo.",
                signing: false,
            });
            return;
        }

        if (this.state.userPwd == "") {
            this.setState({
                message: "Favor preencher o Senha.",
                signing: false,
            });
            return;
        }

        var signRequest = {
            documento: this.state.documentoId,
            orgao: this.state.orgaoSelected,
            cargo: this.state.assinanteSelected,
            senha: this.state.userPwd,
        };

        try {
            let response = await signDocument(signRequest);
            if (response.status === 200 && response.data && response.data.sucesso) {
                this.setState({
                    message: "Documento assinado com sucesso!",
                    signing: false,
                })
            }
            else {

                this.setState({
                    message: response.data.mensagem,
                    signing: false,
                })
            }
        }
        catch (error) {
            this.setState({
                message: error,
                signing: false,
            })
        }
    }
    
    private SignPortal() {
        return (
            <Portal
                trapFocus={{
                    isClickableOutsideFocusTrap: false,
                    focusTriggerOnOutsideClick: false,
                    forceFocusInsideTrapOnOutsideFocus: false,
                    ignoreExternalFocusing: false,
                    disableFirstFocus: false,
                    firstFocusableSelector: "",
                }}
                open={this.state.openSignPortal}
                content={
                    <div
                        className="signPanel"
                        style={{
                            backgroundColor: '#fff',
                            position: 'fixed',
                            left: 0,
                            top: '7rem',
                            zIndex: 1000,
                            padding: '15px',
                            border: '1px solid rgba(34,36,38,.15)',
                            height: '100%',
                            borderTop: 'none',
                        }}
                    >   
                        <Header as="h4">Assinatura de documento</Header>
                        <p>
                            <Dropdown
                                search
                                items={this.state.orgaos}
                                itemToString={(e: any) => {
                                    return e ? e.sigla : "";
                                }}
                                itemToValue={(e: any) => {
                                    return e ? e.id : "";
                                }}
                                getA11ySelectionMessage={this.changeOrgao}
                                placeholder="Orgão"
                            />
                        </p>
                        <p>
                            <Dropdown
                                search
                                items={this.state.assinantes} 
                                itemToString={(e: any) => {
                                    return e ? e.nome : "";
                                }}
                                itemToValue={(e: any) => {
                                    return e ? e.id : "";
                                }}
                                placeholder="Cargo / Função"
                                getA11ySelectionMessage={this.changeAssinatura}
                              />
                        </p>
                        <p>
                            <Input placeholder="Senha" type="password" fluid onChange={this.onPwdChanged.bind(this)} />
                        </p>

                        <Divider />
                        <p className="buttons">
                            <Button content="Cancelar" onClick={this.closeSignPortal} />
                            <Button
                                content={this.state.signing ? "" : "Confirmar"}
                                loading={this.state.signing}
                                primary
                                onClick={this.signDocument.bind(this)} />
                        </p>
                        <p>
                            { this.render_errorMesage() }
                        </p>

                    </div>
                }
            />
        );
    }

    private htmlDecode(input: any) {
        var e = document.createElement('div');
        e.innerHTML = input;
        
        if (e.childNodes.length === 0) {
            return "";
        }

        var data = "<div id='divContent' style='margin-left: 150px;'>";
        for (let i = 0; i < e.childNodes.length; i++) {
            data += e.childNodes[i].nodeValue;
        }
        data += "</div>"
        return data;
    }

    public render(): JSX.Element {
        if (this.state.loadingDocument || this.state.loadingAssinantes || this.state.loadingOrgaos) {
            return (
                <Flex gap="gap.small" hAlign="center" vAlign="center" styles={{ margin: "20% 0" }}>
                    <Flex.Item align="end">
                        <Loader label="Carregando..." />
                    </Flex.Item>
                </Flex>
            );
        }
        else if (this.state.documentoData) {
            return (
                <Flex className="documentView" gap="gap.small" hAlign="center" vAlign="center" column padding="padding.medium">
                    <Flex className="header" gap="gap.medium" vAlign="center" fill>
                        <Flex.Item align="start">
                            <Text className="documentInfo" content={`Número do documento: ${this.state.documentoId}`} />
                        </Flex.Item>
                        <Flex.Item align="end" push>
                            {this.canSign ? <Button content="Assinar" primary onClick={this.openSignPortal} /> : <div />}
                        </Flex.Item>
                    </Flex>
                    <Flex.Item align="center">
                        {this.SignPortal()}
                    </Flex.Item>
                    <Flex.Item align="center">
                        <div className="mainDocument" dangerouslySetInnerHTML={{ __html: this.htmlDecode(this.state.documentoData.visualizar.data) ?? "" }}></div>
                    </Flex.Item>
                </Flex>
            );
        }
        else if (this.state.errorMessage) {
            return (
                <Flex gap="gap.medium" vAlign="center" hAlign="center" column className="errorMessage">
                    <Text error content={this.state.errorMessage} />
                   {/* <Button content="Tentar novamente" icon={<RetryIcon />} onClick={() => this.init() } />*/}
                </Flex>
            );
        }

        return (
            <div></div>
        );
    }
}