import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import {
    Grid,
    Box,
    Flex,
    Button,
    Loader,
    Input,
    Dropdown,
    Divider,
    RadioGroup,
} from "@fluentui/react-northstar";

import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';

import moment from 'moment';

import { getTiposDocumentoData, getTiposDocumentoExternoData, getContatos, getTiposConferenciaDocumentoData, getAssuntos, uploadDocumentoExterno } from '../../api/sei-documento-responses-api';
import { TextArea } from "@fluentui/react-northstar/dist/dts/src";

const browserHistory = createBrowserHistory({ basename: "" });

interface IState {
    theme: string,
    processoId: string,
    unidadeId: string,
    loader: boolean,
    tiposDocumentoLoading: boolean,
    tiposDocumento: any,
    tipoDocumentoFilter: string,
    tipoDocumentoSelected: string,
    dataDocumento: Date | null | undefined,
    numeroDocumento: string,

    tiposDocumentoExterno: any,
    tiposDocumentoExternoSelected: string,
    tiposDocumentoExternoLoading: boolean,
    tipoDocumentoExternoFilter: string,

    formatoDocumento: string,

    tiposConferenciaDocumento: any,
    tiposConferenciaDocumentoLoading: boolean,
    tipoConferenciaDocumentoSelected: string,

    contatos: any,
    contatosLoading: boolean,
    contatoSelected: string,

    remetenteSelected: string,

    assuntos: any
    assuntosLoading: boolean,
    assuntoSelected: string,

    observacoesDocumento: string,

    nivelAcessoDocumento : string,

    file: any,

    message: string,
    uploading: boolean
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}

export class DocumentUploadPage extends React.Component<{}, IState> {

    token?: string | null = null;
    telemetry?: any = null;
    theme?: string | null;
    locale?: string | null;
    userObjectId?: string = "";
    tenantId?: string = "";

    appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };

    constructor(props: any) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.theme = params.get("theme");
        this.telemetry = this.appSettings.telemetry = params.get("telemetry");
        this.locale = params.get("locale");

        this.state = {
            loader: true,
            theme: this.theme ? this.theme : "default",
            processoId: params.get("processoId") ?? "",
            unidadeId: params.get("unidadeId") ?? "",

            tiposDocumento: [],
            tipoDocumentoFilter: "",
            tipoDocumentoSelected: params.get("tipoDocumentoSelected") ?? "",
            tiposDocumentoLoading: false,

            dataDocumento: new Date(),
            numeroDocumento: "",

            tiposDocumentoExterno: [],
            tiposDocumentoExternoLoading: false,
            tiposDocumentoExternoSelected: "",
            tipoDocumentoExternoFilter: "",

            formatoDocumento: "1",

            tiposConferenciaDocumento: [],
            tiposConferenciaDocumentoLoading: false,
            tipoConferenciaDocumentoSelected: "",

            contatos: [],
            contatosLoading: false,
            contatoSelected: "",

            remetenteSelected: "",

            assuntos: [],
            assuntosLoading: false,
            assuntoSelected: "",

            observacoesDocumento: "",

            nivelAcessoDocumento: "0",

            file: [], 

            message: "",

            uploading: false,
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }

    /**
    * Used to initialize Microsoft Teams sdk
    */
    public async componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.userObjectId = context.userObjectId;
            this.tenantId = context.tid;
            this.getTiposDocumentoResponse(this.state.tipoDocumentoFilter);
            this.getTiposDocumentoExternoResponse(this.state.tipoDocumentoFilter);
            this.getTiposConferenciaDocumentoResponse("");
            this.getContatosResponse("");
            this.getAssuntosResponse("");

            this.setState({ loader: false });
        });
    }

    /**
     * Get Tipo Documento
     * */
    private async getTiposDocumentoResponse(filter : any) {
        this.setState({ tiposDocumentoLoading: true });
        
        let response = await getTiposDocumentoData(filter ?? this.state.tipoDocumentoFilter, "");
        if (response.status === 200 && response.data) {
            var tiposDocumento = [];

            tiposDocumento = response.data.data?.map((item: any) => {
                return {
                    id: item.id,
                    nome: item.nome,
                    content: item.nome,
                };
            });

            if (tiposDocumento == null) {
                tiposDocumento = [];
            }

            tiposDocumento.unshift(
                {
                    id: -1,
                    nome: "Externo",
                    content: "Externo",
                }
            );

            this.setState({
                tiposDocumento: tiposDocumento ?? [],
            });
        }

        this.setState({ tiposDocumentoLoading: false });
    }

    /**
     * Get Tipo Documento Externo
     * */
    private async getTiposDocumentoExternoResponse(filter: any) {
        this.setState({ tiposDocumentoExternoLoading: true });

        let response = await getTiposDocumentoExternoData(filter ?? this.state.tipoDocumentoExternoFilter);
        if (response.status === 200 && response.data) {
            

            var tiposDocumentoExterno = [];

            tiposDocumentoExterno = response.data.data?.map((item: any) => {
                return {
                    id: item.id,
                    nome: item.nome,
                    content: item.nome,
                };
            });
            
            this.setState({
                tiposDocumentoExterno: tiposDocumentoExterno ?? [],
            });
        }

        this.setState({ tiposDocumentoExternoLoading: false });
    }

    /**
     * Get Tipo Documento Externo
     * */
    private async getTiposConferenciaDocumentoResponse(filter: any) {
        this.setState({ tiposConferenciaDocumentoLoading: true });

        let response = await getTiposConferenciaDocumentoData(filter ?? this.state.tipoDocumentoExternoFilter);
        if (response.status === 200 && response.data) {
            var tiposConferenciaDocumento = [];

            tiposConferenciaDocumento = response.data.data?.map((item: any) => {
                return {
                    id: item.id,
                    nome: item.descricao,
                    content: item.descricao,
                };
            });

            this.setState({
                tiposConferenciaDocumento: tiposConferenciaDocumento ?? [],
            });
        }

        this.setState({ tiposConferenciaDocumentoLoading: false });
    }

    /**
     * Get Contatos
     * */
    private async getContatosResponse(filter: any) {
        this.setState({ contatosLoading: true });

        let response = await getContatos(filter ?? this.state.contatoSelected);
        if (response.status === 200 && response.data) {
            this.appInsights.trackTrace({ message: `'getContatosResponse' - Request success`, properties: { TenantId: this.tenantId, UserId: this.userObjectId, processoId: this.state.processoId }, severityLevel: SeverityLevel.Information });
            var contatos = [];

            contatos = response.data.data?.map((item: any) => {
                return {
                    id: item.id,
                    header: item.nome,
                    nome: item.nome,
                };
            });

            this.setState({
                contatos: contatos ?? [],
            });
        }


        this.setState({ contatosLoading: false });
    }

    /**
     * Get Assuntos
     * */
    private async getAssuntosResponse(filter: any) {
        this.setState({ assuntosLoading: true });

        let response = await getAssuntos(filter ?? this.state.assuntoSelected);
        if (response.status === 200 && response.data) {

            var assuntos = [];

            assuntos = response.data.data?.map((item: any) => {
                return {
                    id: item.id,
                    header: item.descricao,
                    nome: item.descricao,
                };
            });

            this.setState({
                assuntos: assuntos ?? [],
            });
        }

        this.setState({ assuntosLoading: false });
    }

    /**
     * Handle the upload of document.
     * @param event upload event.
     */
    private handleUpload(event: any) {
        console.log("File : " + event);
        this.setState({
            file: event.target.files[0],
        });
    }

    /**
     * Perform the Upload calling the controller.
     * */
    private async uploadDocument() {
        
        this.setState({
            message: "",
            uploading: true,
        });


        if (this.state.tiposDocumentoExternoSelected === "") {
            this.setState({
                message: "Favor selecionar o tipo do documento.",
                uploading: false,
            });
            return;
        }

        if (this.state.nivelAcessoDocumento === "") {
            this.setState({
                message: "Favor selecionar o nivel de acesso do documento.",
                uploading: false,
            });
            return;
        }

        var uploadRequest = new FormData();

        uploadRequest.append("procedimento", this.state.processoId);
        uploadRequest.append("idSerie", this.state.tiposDocumentoExternoSelected);
        uploadRequest.append("dataElaboracao", this.state.dataDocumento ? moment(this.state.dataDocumento).format("DD/MM/yyyy") : "");
        uploadRequest.append("nivelAcesso", this.state.nivelAcessoDocumento);


        uploadRequest.append("numero", this.state.numeroDocumento);
        uploadRequest.append("idTipoConferencia", this.state.tipoConferenciaDocumentoSelected);
        uploadRequest.append("assuntos", this.state.assuntoSelected);
        uploadRequest.append("interessados", this.state.contatoSelected);
        uploadRequest.append("remetente", this.state.remetenteSelected);

        uploadRequest.append("observacao", this.state.observacoesDocumento);
        uploadRequest.append("idUnidadeGeradoraProtocolo", "");
        

        uploadRequest.append("anexo", this.state.file);

        try {
            let response = await uploadDocumentoExterno(uploadRequest);

            if (response.status === 200 && response.data && response.data.sucesso) {
                
                this.setState({
                    message: "Documento Externo criado com sucesso, protocolo : " + response.data.data.protocoloDocumentoFormatado,
                    uploading: false,
                })
            }
            else {

                this.setState({
                    message: response.data.mensagem,
                    uploading: false,
                })
            }
        }
        catch (error) {

            this.setState({
                message: error,
                uploading: false,
            })
        }
    }


    /**
     * Render the External document render
     * */
    private renderDocumentExternal(): JSX.Element {

        return (
            <Box>
                <Divider content="Tipo do Documento externo" />
                <Dropdown
                    fluid
                    search
                    loading={this.state.tiposDocumentoExternoLoading}
                    loadingMessage="Carregando..."
                    items={this.state.tiposDocumentoExterno}
                    itemToString={(e: any) => {
                        return e ? e.nome : "";
                    }}
                    itemToValue={(e: any) => {
                        return e ? e.id : "";
                    }}
                    getA11ySelectionMessage={{
                        onAdd: (item: any) => {
                            this.setState({
                                tiposDocumentoExternoSelected: item.id,
                            });
                            return item.nome;
                        }
                    }}
                    onSearchQueryChange={(event: any, data: any) => {
                        if (data.searchQuery != null && data.searchQuery.length > 2) {
                            this.getTiposDocumentoExternoResponse(data.searchQuery);
                        }
                    }}
                    placeholder="Selecione o Tipo"
                />
                <Divider content="Data do Documento" />

                <DatePicker
                    isRequired={true}
                    placeholder="Selecione uma data..."
                    ariaLabel="Selecione uma data"
                    value={this.state.dataDocumento ? this.state.dataDocumento : new Date() }
                    onSelectDate={(date: Date | null | undefined): void => {
                        this.setState({
                            dataDocumento: date
                        });
                    }}
                />

                <Divider content="Número / Nome na Árvore" />
                <Input fluid onChange={(event: any) => { this.setState({ numeroDocumento: event.target.value }) }} />

                <Divider content="Formato" />

                <RadioGroup
                    defaultCheckedValue="1"
                    onCheckedValueChange={(event: any, data: any) => { this.setState({ formatoDocumento: data.value }); }}
                    items={[
                        {
                            key: '1',
                            label: 'Nato-Digital',
                            value: '1',
                        },
                        {
                            key: '2',
                            label: 'Digitalizado nessa Unidade',
                            value: '2',
                        },
                    ]}
                />

                { this.state.formatoDocumento == "2" ? 
                    (   <Box>
                            <Divider content="Tipo de Conferência" />
                            <Dropdown
                                fluid
                                loading={this.state.tiposConferenciaDocumentoLoading}
                                loadingMessage="Carregando..."
                                items={this.state.tiposConferenciaDocumento}
                                    itemToString={(e: any) => {
                                        return e ? e.nome : "";
                                    }}
                                    itemToValue={(e: any) => {
                                        return e ? e.id : "";
                                    }}
                                    getA11ySelectionMessage={{
                                        onAdd: (item: any) => {
                                            this.setState({
                                                tipoConferenciaDocumentoSelected: item.id,
                                            });
                                            return item.id;
                                        }
                                    }}
                                    placeholder="Escolha um Tipo de Documento:"
                                />
                            </Box>
                        )
                        :
                    (<div />)
                }

                <Divider content="Remetente" />

                <Dropdown
                    fluid
                    loading={this.state.contatosLoading}
                    loadingMessage="Carregando..."
                    items={this.state.contatos}
                    itemToString={(e: any) => {
                        return e ? e.nome : "";
                    }}
                    itemToValue={(e: any) => {
                        return e ? e.id : "";
                    }}
                    getA11ySelectionMessage={{
                        onAdd: (item: any) => {
                            return item.content;
                        }
                    }}

                    onChange={(event: any, data: any) => {
                        debugger;
                        this.setState({
                            remetenteSelected: data.value.id,
                        });
                    }}
                />


                <Divider content="Interessados" />

                <Dropdown
                    fluid
                    multiple
                    loading={this.state.contatosLoading}
                    loadingMessage="Carregando..."
                    items={this.state.contatos}
                    itemToString={(e: any) => {
                        return e ? e.nome : "";
                    }}
                    itemToValue={(e: any) => {
                        return e ? e.id : "";
                    }}
                    getA11ySelectionMessage={{
                        onAdd: (item: any) => {
                            return item.content;
                        }
                    }}
                    onChange={(event: any, data: any) => {
                        this.setState({
                            contatoSelected: data.value.map((i: any) => i.id).join()
                        });
                    }}
                />

                <Divider content="Selecione o Assunto" />

                <Dropdown
                    fluid
                    multiple
                    placeholder=""
                    loading={this.state.assuntosLoading}
                    loadingMessage="Carregando..."
                    items={this.state.assuntos}
                    itemToString={(e: any) => {
                        return e ? e.nome : "";
                    }}
                    itemToValue={(e: any) => {
                        return e ? e.id : "";
                    }}
                    getA11ySelectionMessage={{
                        onAdd: (item: any) => {
                            return item.content;
                        }
                    }}
                    onChange={(event: any, data: any) => {
                        this.setState({
                            assuntoSelected: data.value.map((i: any) => i.id).join()
                        });
                    }}
                />

                <Divider content="Observações" />
                <TextArea fluid onChange={(event: any) => { this.setState({ observacoesDocumento: event.target.value }) }} />

                <Divider content="Nível de Acesso" />

                <RadioGroup
                    defaultCheckedValue="0"
                    onCheckedValueChange={(event: any, data: any) => { this.setState({ nivelAcessoDocumento: data.value }); }}
                    items={[
                        {
                            key: '0',
                            label: 'Público',
                            value: '0',
                        },
                        {
                            key: '1',
                            label: 'Restrito',
                            value: '1',
                        },
                        {
                            key: '2',
                            label: 'Sigiloso',
                            value: '2',
                        },
                    ]}
                />

                <Divider content="Selecione o Arquivo" />
                <input type="file" onChange={(event) => { this.handleUpload(event); }} />

                <Button
                    content={this.state.uploading ? "" : "Confirmar"}
                    loading={this.state.uploading}
                    primary
                    onClick={this.uploadDocument.bind(this)} />
                
            </Box>
        );
    }

    /**
     * Render Message
     * */
    public renderMesage(): JSX.Element {
        if (this.state.message != "") {
            return (<div> { this.state.message} </div>);
        }
        else {
            return (<div />);
        }
    }

    /**
     * Render this component.
     * */
    public render(): JSX.Element {
        if (this.state.loader) {
            return (
                <Grid>
                    <Loader label="Carregando..." />
                </Grid>
            );
        }
        else if (this.state.tipoDocumentoSelected === "Externo") {
            return (
                <Flex gap="gap.small" hAlign="center" vAlign="center" column padding="padding.medium">
                    <Flex.Item>
                        {this.renderDocumentExternal()}
                    </Flex.Item>
                    <Flex.Item>
                        {this.renderMesage()}
                    </Flex.Item>
                </Flex>
            );
        }
        else {
            return (
                <Flex gap="gap.small" hAlign="center" vAlign="center" column padding="padding.medium">
                    <Flex.Item align="center">
                        <Box>
                            <Divider content="Tipo" />
                            <Dropdown
                                search
                                loading={this.state.tiposDocumentoLoading}
                                loadingMessage="Carregando..."
                                items={this.state.tiposDocumento}
                                itemToString={(e: any) => {
                                    return e ? e.nome : "";
                                }}
                                itemToValue={(e: any) => {
                                    return e ? e.id : "";
                                }}
                                getA11ySelectionMessage={{
                                    onAdd: (item: any) => {
                                        this.setState({
                                            tipoDocumentoSelected: item.nome,
                                        });
                                        return item.nome;
                                    }
                                }}
                                onSearchQueryChange={(event: any, data: any) => {
                                    if (data.searchQuery != null && data.searchQuery.length > 2) {
                                        this.getTiposDocumentoResponse(data.searchQuery);
                                    }}}
                                placeholder="Escolha o tipo do Documento"
                            />


                            {this.state.tipoDocumentoSelected === "Externo" ? this.renderDocumentExternal() : (<div />)}


                        </Box>
                    </Flex.Item>
                </Flex>
            );
        }
    }
}