import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import VerticalMenu from "../sei-tour/shared/vertical-menu";
import { createBrowserHistory } from "history";
import { default as TourMenuItems } from "../../config/tour-items";
import {
    Grid,
    Divider,
    Segment,
    Label,
    Menu,
    Checkbox,
    Box,
    Text,
    Flex,
    Button,
    Header,
    Loader
} from "@fluentui/react-northstar";
import './sei-app-tour.css';

import { getApplicationInsightsInstance } from "../../helpers/app-insights";

interface IState {
    theme: string,
    loader: boolean,
    message: string,
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}

const browserHistory = createBrowserHistory({ basename: "" });

export default class SeiAppTour extends React.Component<{}, IState> {
    telemetry?: any = null;
    theme?: string | null;
    appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };

    constructor(props: any) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.telemetry = this.appSettings.telemetry = params.get("telemetry");

        this.state = {
            loader: true,
            theme: this.theme ? this.theme : "default",
            message: ""
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }


    public render(): JSX.Element {
        return (
            <div className="sei-app-tour">
                <Divider />
                <Grid styles={{
                    gridTemplateColumns: "repeat(12, 1fr)",
                }}>
                    <VerticalMenu menuWidth={3} menuItems={TourMenuItems} />
                </Grid>
            </div>
        );
    }

}