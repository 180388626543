import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { Input, Button, Flex, Layout, Label, Dropdown, Alert, Text } from "@fluentui/react-northstar";
import { getSeiAuthenticationToken } from '../../api/sei-authentication-api';
import { getSeiOrgaosByTenantId } from '../../api/sei-orgao-api';
import { getApplicationInsightsInstance } from "../../helpers/app-insights";
import { createBrowserHistory } from "history";

import SeiHelpPage from '../sei-help/sei-help';

import './sei-auth.scss'

interface IState {
    userName: string,
    userPwd: string,
    urlOrgaoSei: string,
    appState: string,
    message: string,

    userId: string,
    tenantId: string,

    orgaoId: string,
    orgaos: any,
    orgaosLoading: boolean,
    orgaoSelected: number,

    loading: boolean,

    helpState: boolean,
}

export interface IAppSettings {
    token: string | null,
    telemetry: string | null,
    theme: string | null
}

const browserHistory = createBrowserHistory({ basename: "" });

export default class SeiAuth extends React.Component<RouteComponentProps, IState> {
    telemetry: string = "";
    
    appSettings: IAppSettings = { telemetry: "", theme: "", token: "" };

    constructor(props: RouteComponentProps) {
        super(props);
        this.handleState = this.handleState.bind(this);

        let search = new URLSearchParams(window.location.search);
        let stateParameter = search.get("state");

        let loginState = stateParameter ? JSON.parse(window.atob(stateParameter)) : JSON.parse("{}");

        this.telemetry = loginState["telemetry"];

        console.log("State Data:");
        console.log(loginState);

        this.state = {
            userName: loginState["user"] ?? "",
            userPwd: "",
            urlOrgaoSei: loginState["orgaoUrl"] ?? "",
            appState: loginState["state"] ?? "",
            orgaoId: loginState["orgaoId"] ?? "",
            userId: loginState["userId"] ?? "",
            tenantId: loginState["tenantId"] ?? "",
            message: "",
            orgaos: [],
            orgaosLoading: false,
            orgaoSelected: 0,
            loading: false,
            helpState: false,
        }

        window.localStorage.setItem("appsettings", JSON.stringify(this.appSettings));
    }

    /**
    * Used to initialize Microsoft Teams sdk
    */
    public async componentDidMount() {
        document.title = "SEI 365 - Entrar";

        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.getOrgaosResponse("");
        });
    }

    private async getOrgaosResponse(filterOrgaoName: any) {
        this.setState({ orgaosLoading: true });

        let response = await getSeiOrgaosByTenantId(this.state.tenantId, filterOrgaoName);
        if (response.status === 200 && response.data) {
            var orgaosResponse = [];
            
            orgaosResponse = response.data?.map((item: any) => {
                return {
                    id: item.orgaoId,
                    nome: item.orgaoName,
                    content: item.orgaoName
                };
            });

            this.setState({
                orgaos: orgaosResponse ?? [],
            })
        }
        else {
            console.log("Erro " + response.data);
        }
        this.setState({ orgaosLoading: false });
    }

    private onSignIn() {
        this.setState({ loading: true });

        var userName = this.state.userName;
        //var userPwd = window.btoa(this.state.userPwd);
        var userPwd = this.b64EncodeUnicode(this.state.userPwd);
        var urlOrgaoSei = this.state.urlOrgaoSei;
        var orgaoId = this.state.orgaoId == "" ? "0" : this.state.orgaoId;
        var appState = JSON.stringify(this.state.appState);

        if (urlOrgaoSei == "") {
            this.setState({ loading: false });
            this.setState({
                message: "O campo Orgão é obrigatório!",
            });
            return;
        }

        if (userName == "") {
            this.setState({ loading: false });
            this.setState({
                message: "O campo Usuário é obrigatório!",
            });
            return;
        }

        if (userPwd == "") {
            this.setState({ loading: false });
            this.setState({
                message: "O campo Senha é obrigatório!",
            });
            return;
        }

        if (appState == "") {
            this.setState({ loading: false });
            this.setState({
                message: "Dados inválidos, por gentileza, feche e inicie novamente o processo de login.",
            });
            return;
        }

        getSeiAuthenticationToken(urlOrgaoSei, orgaoId, userName, userPwd, appState).then(result => {
            console.log("AppState", appState);
            console.log("Result from Auth: ");
            console.log(result)
            if (result.data.sucesso) {
                this.setState({ loading: false });
                //window.close();

                microsoftTeams.authentication.notifySuccess(result.data);
            }
            else {
                this.setState({ loading: false });

                if (result.data.mensagem !== "") {
                    this.setState({
                        message: result.data.mensagem,
                    });
                    return;
                }

                this.setState({
                    message: "Usuário ou senha inválidos.",
                });
            }
        });
    }

    private onUserNameChanged(event: any) {
        var userName = event.target.value;
        this.setState({
            userName: userName
        });
    }

    private onUserPwdChanged(event: any) {

        if (event.key === 'Enter') {
            console.log(event.key);
            this.onSignIn();
        }

        var userPwd = event.target.value;
        this.setState({
            userPwd: userPwd
        });
    }

    private onOrgaoChanged(event: any) {
        var urlOrgaoSei = event.target.value;
        this.setState({
            urlOrgaoSei: urlOrgaoSei
        });
    }

    // convert a Unicode string to a string in which
    // each 16-bit unit occupies only one byte
    private b64EncodeUnicode(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    public render_errorMesage(): JSX.Element {
        if (this.state.message != "") {
            return (<Alert danger content={this.state.message} />);
        }
        else {
            return (<div />);
        }
    }

    private handleState(state: boolean) {
        this.setState({ helpState: state })
    }

    private renderHelp() {
        return (
            <SeiHelpPage handler={this.handleState} />
        );
    }

    private renderAuth() {
        return (
            <div className="content-form">
                <img src="/images/logo-sei.svg" alt="Top365 Sei Logo" />
                <p>Sistema Eletrônico de Informações</p>
                <h4>Utilize suas credenciais de acesso ao SEI:</h4>

                <div className="formContainer">
                    <div className="form-group">
                        <label>Usuário</label>
                        <Input placeholder="Usuário do SEI" onChange={this.onUserNameChanged.bind(this)} value={this.state.userName} />
                    </div>
                    <div className="form-group">
                        <label>Senha</label>
                        <Input placeholder="Senha do SEI" type="password" onChange={this.onUserPwdChanged.bind(this)} />
                    </div>

                    {this.state.orgaos.length > 1 &&
                        <div className="form-group-dd">
                            <label>Órgão</label>
                            <Dropdown
                                fluid
                                defaultActiveSelectedIndex={this.state.orgaoSelected}
                                loading={this.state.orgaosLoading}
                                loadingMessage="Carregando..."
                                items={this.state.orgaos}
                                itemToString={(e: any) => {
                                    return e ? e.nome : "";
                                }}
                                itemToValue={(e: any) => {
                                    return e ? e.id : "";
                                }}
                                getA11ySelectionMessage={{
                                    onAdd: (item: any) => {
                                        this.setState({
                                            orgaoId: item.id,
                                        });
                                        return item.id;
                                    }
                                }}
                                placeholder="Orgão"
                            />
                        </div>
                    }

                    <div className="form-group">
                        <Input placeholder="Orgão" onChange={this.onOrgaoChanged.bind(this)} value={this.state.urlOrgaoSei} style={{ display: 'none' }} />
                    </div>
                    <div className="form-group login-btn">
                        <Button content="Entrar" loading={this.state.loading} disabled={this.state.loading} primary className="sign-in-button" onClick={this.onSignIn.bind(this)} />
                    </div>
                    <div>
                        {this.render_errorMesage()}
                    </div>
                    <div className="form-group">
                        <Text size="smaller" content={`Em caso de dúvidas ou problemas com o usuário do SEI, entre em contato com um órgão responsável.`} />
                        <Text size="smaller" content={`Para mais informações ou ajuda de um especialista,`} />
                        <span className="link" onClick={() => this.handleState(true)}>clique aqui</span>
                    </div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <div className="defaultContainer row">
                <div className="col img-bg">
                    <img src="/images/bg-sei.png" alt="Top365 Sei Logo" />
                </div>
                <div className="col form-col">
                    {!this.state.helpState && this.renderAuth()}
                    {this.state.helpState && this.renderHelp()}
                </div>
            </div>
        );
    }
};
